import { Slider } from "../_super/_slider";

const sliderName = "topTopic"; //swiperのクラス名
const noSliderName = "noSwiper"; //非swiperのクラス名

//スライダー用関数
export const topTopicSlider = (sliderElement, modifierOptions) => {
  const parent = sliderElement.closest(`.${sliderName}_container`);
  let options = {
    slidesPerView: "auto",
    centeredSlides: false,
    speed: 1000,
    navigation: {
      nextEl: parent.querySelector(
        `.${sliderName}_button.${sliderName}_button-next`
      ),
      prevEl: parent.querySelector(
        `.${sliderName}_button.${sliderName}_button-prev`
      ),
    },
    pagination: {
      el: parent.querySelector(`.${sliderName}_pagination`),
      clickable: true,
    },
    spaceBetween: 20,
    breakpoints: {
      737: {
        spaceBetween: 64,
      },
    },
    ...modifierOptions,
  };

  if (
    sliderElement.querySelectorAll(`.${sliderName}_slide.swiper-slide`)
      .length >= 4
  ) {
    //画像が4枚以上の場合
    const slider = new Slider({
      sliderElement,
      options,
    });

    let resizeTimer = null;

    window.addEventListener("resize", (e) => {
      //resizeTimerがあればタイマーを解除
      if (resizeTimer != null) {
        clearTimeout(resizeTimer);
      }
      resizeTimer = setTimeout(() => {}, 500);
    });
  } else if (
    sliderElement.querySelectorAll(`.${sliderName}_slide.swiper-slide`)
      .length >= 2
  ) {
    //画像が2枚以上の場合
    const slider = new Slider({
      sliderElement,
      options,
    });

    sliderElement.classList.add(noSliderName);

    //PCの場合は削除
    slider.responsiveSP();

    let resizeTimer = null;

    window.addEventListener("resize", (e) => {
      //resizeTimerがあればタイマーを解除
      if (resizeTimer != null) {
        clearTimeout(resizeTimer);
      }
      resizeTimer = setTimeout(() => {
        slider.responsiveSP();
      }, 500);
    });
  } else {
    //画像が1枚以下の場合
    removeBtn(sliderElement);

    sliderElement.classList.add(noSliderName);
  }
};

//ボタンを削除する関数
const removeBtn = (sliderElement) => {
  sliderElement
    .closest(`.${sliderName}_container`)
    .querySelector(`.${sliderName}_accessories`)
    .remove();
};
