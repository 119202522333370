export class FixScreen {
  constructor(c) {
    this.target = c.target;
    this.scrollPos = 0;
  }

  fixed() {
    this.scrollPos = window.pageYOffset;
    this.target.style.top = `-${this.scrollPos}px`;
    this.target.style.position = "fixed";
    this.target.style.maxWidth = "100%";
  }

  static() {
    this.target.style.top = `${this.scrollPos}px`;
    this.target.style.position = "static";
    this.target.style.maxWidth = "none";
    window.scrollTo(0, this.scrollPos);
  }
}
