import { sliderTplOptions } from "../_variable/_sliderTplOptions";

//スライダー用クラス
export class Slider {
  constructor(c) {
    this.sliderElement = c.sliderElement; //swiper本体
    this.options = { ...sliderTplOptions, ...c.options }; //テンプレートオプションとカスタムオプションのマージ
    this.slider = new Swiper(this.sliderElement, this.options); //swiperを初期化
    this.BP = 736; //ブレークポイント
  }

  init() {
    this.slider.destroyed &&
      (this.slider = new Swiper(this.sliderElement, this.options)); //swiperが削除されていれば初期化する
  }

  destroy() {
    !this.slider.destroyed && this.slider.destroy(); //swiperが初期化されていれば削除する
  }

  responsivePC() {
    if (window.innerWidth > this.BP) {
      this.init();
    } else {
      this.destroy();
    }
  }

  responsiveSP() {
    if (window.innerWidth <= this.BP) {
      this.init();
    } else {
      this.destroy();
    }
  }
}
