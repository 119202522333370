import { observerTplOptions } from "../_variable/_observerTplOptions";

//交差監視用クラス
export class Observer {
  constructor(c) {
    this.options = { ...observerTplOptions, ...c.options }; //テンプレートオプションとカスタムオプションのマージ
    this.targetElements = c.targetElements;
    this.observer = new IntersectionObserver(c.intersection, this.options); //インスタンス初期化
  }

  init() {
    this.targetElements.map((targetElement) => {
      //監視の実行
      this.observer.observe(targetElement);
    });
  }
}