import { ScrollScreen } from "../_static/_scrollScreen";

export const gHeaderScroll = (
	gHeader = document.querySelector(".gHeader"),
	sideNav = document.querySelector(".sideNav")
) => {
	if (!gHeader) return;

	let startPos = 0,
		scrollPos = 0;

	window.addEventListener("scroll", (e) => {
		scrollPos = window.pageYOffset;

		ScrollScreen.scrollX(gHeader);

		if (startPos < scrollPos && scrollPos > gHeader.clientHeight) {
			if (!gHeader.classList.contains("gHeader-reset")) {
				ScrollScreen.scrollDown(gHeader, sideNav);
			} else {
				gHeader.classList.remove("gHeader-reset");
			}
		} else {
			ScrollScreen.scrollUp(gHeader, sideNav);
		}

		startPos = scrollPos;
	});
};
