import { Observer } from "../_super/_observer";
import { StyleToggle } from "../_static/_styleToggle";

//交差監視用関数（サンプル）
export const topObserver = (
  targetElements = [...document.querySelectorAll("[ data-observer='top']")]
) => {
  if (!targetElements) return;

  //交差監視用オプション（カスタム）
  let options = {
    rootMargin: "0%",
  };

  //交差時に実行する関数
  const intersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        StyleToggle.active(entry.target);
      }
    });
  };

  //インスタンスを生成
  const observer = new Observer({
    options,
    targetElements,
    intersection,
  });

  //監視の実行
  observer.init();
};
