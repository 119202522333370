import { Hamburger } from "../_super/_hamburger";
import { FixScreen } from "../_super/_fixScreen";

export const hamburger = (overlay) => {
  if (document.querySelector(".hamburgerBtn")) {
    //ハンバーガーメニューのインスタンスを生成
    const hamburger = new Hamburger({
      btn: document.querySelector(".hamburgerBtn"),
      menu: document.querySelector(".hamburgerMenu"),
      overlay, //菊池さんのオーバーレイ
    });

    //画面固定用のインスタンスを生成
    const fixScreen = new FixScreen({ target: document.querySelector("body") });

    //ハンバーガーメニューのボタンを押したとき
    hamburger.btn.addEventListener("click", (e) => {
      if (e.currentTarget.dataset.style === "active") {
        hamburger.close();
        fixScreen.static();
      } else {
        hamburger.open();
        fixScreen.fixed();
        hamburger.gHeader.classList.add("gHeader-reset"); //メニューが閉じたときヘッダーメニューが隠れないように調整
      }
    });

    //オーバーレイを押したとき
    $(".overlay").on("click", function (e) {
      hamburger.close();
      fixScreen.static();
    });
  }
};
