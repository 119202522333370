import { Anime } from "../_static/_anime";

export class Acc {
  constructor(c) {
    this.accBtn = c.accBtn;
    this.accBox = c.accBox;
    this.accBoxH = 0;
  }

  init() {
    this.accBox.style.height = "auto";
    this.accBoxH = this.accBox.clientHeight;
  }

  open() {
    this.init();
    Anime.slideToggle(this.accBox, 0, `${this.accBoxH}px`, 300);
    this.accBox.style.height = "auto";
  }

  close() {
    this.init();
    Anime.slideToggle(this.accBox, `${this.accBoxH}px`, 0, 300);
    this.accBox.style.height = 0;
  }
}
