import { Acc } from "../_super/_acc";
import { StyleToggle } from "../_static/_styleToggle";

//アコーディオン（PCヘッダー用）
export const gHeaderAccPC = (accUnit) => {
  accUnit.on({
    mouseenter: function () {
      accUnit.attr("data-gHeaderAcc-unit", "open");
      accUnit.find("[data-gHeaderAcc-contents]").stop().slideDown();
    },
    mouseleave: function () {
      accUnit.attr("data-gHeaderAcc-unit", "close");
      accUnit.find("[data-gHeaderAcc-contents]").stop().slideUp();
    },
  });
};

//アコーディオン（SPヘッダー用）
export const gHeaderAccSP = (accUnit) => {
  accUnit.forEach((accUnit) => {
    const accBtn = accUnit.querySelector("[data-accBtn]");
    const accBox = accUnit.querySelector("[data-accBox]");
    const acc = new Acc({ accBtn, accBox });

    accBtn.closest(".gHeader_unit").addEventListener("click", (e) => {
      if (accBtn.dataset.style === "active") {
        acc.close();
        StyleToggle.inactive(acc.accBtn, acc.accBox);
      } else {
        acc.open();
        StyleToggle.active(acc.accBtn, acc.accBox);
      }
    });
  });
};
