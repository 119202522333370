import { StyleToggle } from "../_static/_styleToggle";

//ハンバーガーメニューの開閉用クラス
export class Hamburger {
  constructor(c) {
    this.gHeader = document.querySelector(".gHeader");
    this.btn = c.btn;
    this.menu = c.menu;
    this.overlay = c.overlay;
  }

  open() {
    this.overlay.open();
    StyleToggle.active(this.btn, this.menu);
  }

  close() {
    this.overlay.close();
    StyleToggle.inactive(this.btn, this.menu);
  }
}
