import { StyleToggle } from "./_styleToggle";

//画面スクロール用クラス
export class ScrollScreen {
  constructor() {}

  //横スクロール処理
  static scrollX(...targets) {
    targets.forEach((target) => {
      target.style.left = `-${window.pageXOffset}px`;
    });
  }

  //縦スクロール（下方向）
  static scrollDown(...targets) {
    targets.forEach((target) => {
      StyleToggle.active(target);
    });
  }

  //縦スクロール（上方向）
  static scrollUp(...targets) {
    targets.forEach((target) => {
      StyleToggle.inactive(target);
    });
  }
}
