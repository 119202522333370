import {Acc} from "../_super/_acc";
import {StyleToggle} from "../_static/_styleToggle";

//アコーディオン（SPフッター用）
export const gFooterAccSP = (accUnits) => {
	const BP = 736;
	let device = window.innerWidth > BP ? "pc" : "sp";
	let accUnitList = [];

	accUnits.forEach((accUnit, index) => {
		const accBtn = accUnit.querySelector("[data-accBtn]");
		const accBox = accUnit.querySelector("[data-accBox]");
		accUnitList[index] = new Acc({accBtn, accBox});

		if (device === "sp") {
			accUnitList[index].close();
			StyleToggle.inactive(
				accUnitList[index].accBtn,
				accUnitList[index].accBox
			);
			accBtn.addEventListener("click", (e) => {
				if (accBtn.dataset.style === "active") {
					accUnitList[index].close();
					StyleToggle.inactive(
						accUnitList[index].accBtn,
						accUnitList[index].accBox
					);
				} else {
					accUnitList[index].open();
					StyleToggle.active(
						accUnitList[index].accBtn,
						accUnitList[index].accBox
					);
				}
			});
		} else {
			accUnitList[index].open();
			StyleToggle.active(accUnitList[index].accBtn, accUnitList[index].accBox);
		}
	});

	window.addEventListener("resize", () => {
		if (device !== (window.innerWidth > BP ? "pc" : "sp")) {
			device = window.innerWidth > BP ? "pc" : "sp";
			accUnits.forEach((accUnit, index) => {
				if (device === "sp") {
					accUnitList[index].close();
					StyleToggle.inactive(
						accUnitList[index].accBtn,
						accUnitList[index].accBox
					);
				} else {
					accUnitList[index].open();
					StyleToggle.active(
						accUnitList[index].accBtn,
						accUnitList[index].accBox
					);
				}
			});
		}
	});
};
