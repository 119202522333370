export class StyleToggle {
  constructor() {}

  static active(...targetElements) {
    //スタイルの有効化
    targetElements.forEach((targetElement) => {
      targetElement.dataset.style = "active";
    });
  }

  static inactive(...targetElements) {
    //スタイルの無効化
    targetElements.forEach((targetElement) => {
      targetElement.dataset.style = "inactive";
    });
  }
}
