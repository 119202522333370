import { Observer } from "../_super/_observer";
import { StyleToggle } from "../_static/_styleToggle";

//交差監視用関数（サンプル）
export const scrollTopObserver = (
	targetElements = [document.querySelector(".gFooter")]
) => {
	if (!targetElements) return;

	const scrollTop = document.querySelector(".scrollTop");

	const bp = 736;

	//交差監視用オプション（カスタム）
	let options = {
		rootMargin: "-45px 0px",
	};

	//交差時に実行する関数
	const intersection = (entries) => {
		entries.forEach((entry) => {
			if (window.innerWidth > bp) {
				if (entry.isIntersecting) {
					StyleToggle.active(scrollTop);
					scrollTop.style.bottom = `${entry.target.clientHeight - 25}px`;
				} else {
					StyleToggle.inactive(scrollTop);
					scrollTop.style.bottom = "30px";
				}
			} else {
				StyleToggle.inactive(scrollTop);
				scrollTop.style.bottom = "10px";
			}
		});
	};

	//インスタンスを生成
	const observer = new Observer({
		options,
		targetElements,
		intersection,
	});

	//監視の実行
	observer.init();
};
